.coord-control label,
.coord-control input,
.coord-control button {
	border-width: 1px;
	border-style: solid;
	font-size: 1.4rem;
	margin: 0;
	line-height: inherit;
	box-sizing: border-box;
}
.coord-control label,
.coord-control input {
	font-family: 'Ubuntu Mono', monospace;
	padding: .3rem;
}
.coord-control button {
	font-family: inherit;
}
.coord-control label {
	font-weight: bold;
	width: 3rem;
	text-align: right;
	display: inline-block;
}
.coord-control input {
	width: 11rem;
	border-radius: 0;
}
.coord-control label.lat {
	border-top-left-radius: .5rem;
}
.coord-control input.lat {
	border-top-right-radius: .5rem;
}
.coord-control button {
	border-bottom-left-radius: .5rem;
	border-bottom-right-radius: .5rem;
	width: 14rem;
}