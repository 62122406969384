.finder {
	width: 100%;
	height: 100%;
}
.finder input {
	font-size: 1.5rem;
	border-radius: .3rem;
	padding: 0 .5rem;
}
.finder table {
	max-width: 50rem;
	background-color: white;
}
.finder tbody tr:hover {
	cursor: pointer;
}