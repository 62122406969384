html, body, #root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}
main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-family: 'Titillium Web',sans-serif;
}

button {
	font-weight: bold;
	font-family: 'Titillium Web',sans-serif;
	font-size: 1rem;
}

#id-number {
	font-size: 1.5rem;
	font-weight: bold;
}

.panel {
	flex: 1 1 auto;
	overflow: auto;
}

nav {
	flex: 0 0 auto;
	display: flex;
	box-shadow: 0 -1px 2px 0 #999;
	z-index: 0;
}
nav > input[type=image] {
	flex: 1 1 auto;
	height: 2rem;
	padding: 0.4rem;
	/* border: 1px solid grey; */
	border-radius: 0;
}
nav > input:disabled {
	opacity: 0.5;
}

.controls {
	display: flex;
	flex: 0 0 auto;
}
.controls > button {
	flex: 1 1 auto;
	border-radius: 0;
	margin: 0;
}

#gps-panel h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-weight: bold;
	font-size: 1.8rem;
	line-height: 1.2rem;
}

#work-panel > table {
	max-width: 25rem;
	margin: auto;
}
#work-panel button {
	width: 6rem;
	text-align: right;
}
#work-panel .inputs label {
	width: 8rem;
	text-align: right;
	font-weight: bold;
	display: inline-block;
}
#work-panel .inputs input {
	width: 10rem;
	font-size: inherit;
}

#login-panel {
	padding: 1rem;
}
#login-panel label {
	text-align: center;
	margin-bottom: 0;
}
#login-panel * {
	font-size: 1.4rem;
	font-weight: bold;
}
#login-panel input {
	padding-left: 0.5rem;
}
#login-panel button {
	padding: 0.3rem 1.5rem;
	margin: 0.5rem;
}

#upload-panel textarea {
	width: 20rem;
	height: 5rem;
	display: block;
	margin: auto;
	font-family: inherit;
	font-size: inherit;
	padding: .2rem .4rem;
	box-sizing: border-box;
}
#upload-panel button {
	font-size: 1.5rem;
	padding: 0.4rem;
	width: 8rem;
	margin: 0.5rem;
}

.info-table {
	margin: auto;
	border-collapse: collapse;
}
.info-table thead {
	background-color: #ddd;
}
.info-table tbody tr:nth-child(even) {
	background-color: #eee;
}
.info-table td {
	padding: 0 0.5rem;
}
.coord-control label,
.coord-control input,
.coord-control button {
	border-width: 1px;
	border-style: solid;
	font-size: 1.4rem;
	margin: 0;
	line-height: inherit;
	box-sizing: border-box;
}
.coord-control label,
.coord-control input {
	font-family: 'Ubuntu Mono', monospace;
	padding: .3rem;
}
.coord-control button {
	font-family: inherit;
}
.coord-control label {
	font-weight: bold;
	width: 3rem;
	text-align: right;
	display: inline-block;
}
.coord-control input {
	width: 11rem;
	border-radius: 0;
}
.coord-control label.lat {
	border-top-left-radius: .5rem;
}
.coord-control input.lat {
	border-top-right-radius: .5rem;
}
.coord-control button {
	border-bottom-left-radius: .5rem;
	border-bottom-right-radius: .5rem;
	width: 14rem;
}
.finder {
	width: 100%;
	height: 100%;
}
.finder input {
	font-size: 1.5rem;
	border-radius: .3rem;
	padding: 0 .5rem;
}
.finder table {
	max-width: 50rem;
	background-color: white;
}
.finder tbody tr:hover {
	cursor: pointer;
}
